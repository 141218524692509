<template>
  <!--
    Configure Actioncam CropRect with an Image Cropper    
  -->
  <div>
    <LoadingPlaceholder v-if="loading" />    
    <template v-else>
      <div
        v-if="actioncamImage && configuration && cropBox"
        class="mt-4"
      >
        <vue-cropper
          v-if="!rotate"
          id="actioncamImageSelector"
          ref="cropper"
          :class="cropperBelowMinWidth ? 'cropperRed' : null"   
          :aspect-ratio="16 / 9"
          :src="`data:image/png;base64,${ actioncamImage.base64Data }`"
          alt="Source Image"
          :zoomable="false"
          :autocrop="true"
          :data="cropBox"
          :cropmove="cropMove"
        />
        <vue-cropper
          v-else
          id="actioncamImageSelector"
          ref="cropper"
          :class="cropperBelowMinWidth ? 'cropperRed' : null"   
          :aspect-ratio="16 / 9"
          :src="actioncamImage.rotatedBase64Data"
          alt="Source Image"
          :zoomable="false"
          :autocrop="true"
          :data="cropBox"
          :cropmove="cropMove"
        />        
      </div>
      <button
        class="btn btn-primary float-right mt-2"
        @click="saveConfigurationAndRestartApp()"
      >
        <font-awesome-icon
          class="mr-2"
          icon="sync-alt"
        /><span>{{ $t('deviceDetailConfigurationEditComponent.saveAndRestart') }}</span>
      </button>
      <button 
        class="btn btn-primary float-right mt-2 mr-2" 
        @click="saveConfiguration()"
      >
        <font-awesome-icon
          class="mr-2"
          icon="save"
        /><span>Save Configuration</span>
      </button>
    </template> 
  </div>
</template>
  
<script>
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';
import EXIF from 'exif-js';

export default {
  name: 'ActioncamCropRectConfiguration',
  components: { VueCropper},
  props: {
    installationId: {
      type: String,
      required: true
    },
    rotate: {
      type: Boolean,
      required: true,
      default: false
    },
    deviceId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      configuration: null,
      configurationDeviceUiid: null,
      loading: true,
      actioncamImage: null,
      scaleFactor: null,
      cropperBelowMinWidth: false
    }
  },
  computed: {
    cropBox () {
      if (this.configuration == null) {
        return null;
      }
      if (this.configuration.cropRect == null) {
        return null;
      }
      if (this.configuration.cropRect.length  === 0) {
        return null;
      }
      let data = {
        "left": this.configuration.cropRect[0],
        "top": this.configuration.cropRect[1],
        "width": this.configuration.cropRect[2],
        "height": this.configuration.cropRect[3]
      }
      return data;
    }
  },
  watch: {
    rotate (val) {
      if(val) {
        this.loading = true;
        this.mf(`data:image/png;base64,${ this.actioncamImage.base64Data }`, 90)
      }
      else {
        this.loading = true;
        setTimeout(this.stopLoading, 1000);
        setTimeout(this.calculateScaleFactor, 1100);
        setTimeout(this.setData, 1100);
      }
    }
  },
  async created () {
    await this.getActioncamImage();
    await this.getConfiguration();
    setTimeout(this.calculateScaleFactor, 100);
    setTimeout(this.setData, 100);
  },
  methods: {
    mf (srcBase64, degrees) {
        const canvas = document.createElement('canvas');
        let ctx = canvas.getContext("2d");
        let image = new Image();

        image.onload = () => {
          canvas.width = degrees % 180 === 0 ? image.width : image.height;
          canvas.height = degrees % 180 === 0 ? image.height : image.width;

          ctx.translate(canvas.width / 2, canvas.height / 2);
          ctx.rotate(degrees * Math.PI / 180);
          ctx.drawImage(image, image.width / -2, image.height / -2);
          this.actioncamImage.rotatedBase64Data = canvas.toDataURL();
          this.loading = false;
          setTimeout(this.calculateScaleFactor, 100);
          setTimeout(this.setData, 100);
        };

       image.src = srcBase64;
    },
    async refresh () {
      await this.getActioncamImage();
      await this.getConfiguration();
    },
    //CROPPER
    cropMove () {
      let result = this.getCropBoxData();
      if((result.width * this.scaleFactor) < this.configuration.cropRect[4])
      {
        this.cropperBelowMinWidth = true;
      }
      else
      {
        this.cropperBelowMinWidth = false;
      }
    },
    calculateScaleFactor () {
      this.scaleFactor = this.$refs.cropper.getImageData().naturalWidth / this.$refs.cropper.getImageData().width;
    },
    getCropBoxData () {
      return this.$refs.cropper.getCropBoxData();
    },
    stopLoading () {
      this.loading = false;
    },
    setData () {
      let cropRect ={
        "left": this.configuration.cropRect[0] / this.scaleFactor,
        "top": this.configuration.cropRect[1] / this.scaleFactor,
        "width": this.configuration.cropRect[2] / this.scaleFactor,
        "height": this.configuration.cropRect[3] / this.scaleFactor
      }
      this.$refs.cropper.setCropBoxData(cropRect);
    },
    //API
    async getActioncamImage () {
      this.$emit("cropLoading", true);
      this.loading = true;
      await this.axios.get(`/Actioncam/GetActioncamPhoto/${ this.deviceId }`)
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.data == null) {
            return;
          }
          this.actioncamImage = response.data;
          this.emitExif();
        })
    },
    async getConfiguration () {
      await this.axios.get(`/Actioncam/GetActioncamConfiguration/${ this.installationId }/${ this.deviceId }`)
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.data == null) {
            return;
          }
          this.configuration = response.data;
        })
        .finally(() => {
          this.loading = false;
          this.$emit("cropLoading", false);
        })
    },
    async saveConfigurationAndRestartApp (){
      if(!this.configurationDeviceUiid)
      {
        await this.getRestartDeviceUiid();
      }
      await this.saveConfiguration();
      await this.restartApp();
    },
    async getRestartDeviceUiid () {
      await this.axios.get(`/CentralDeviceManagement/GetFromInstallationId?installationId=${ this.installationId }`)
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.data == null) {
            return;
          }
          let configurationDevice = response.data.filter(p => p.applicationType == 'pt_controller' || p.applicationType == 'sc_finish');
          if(configurationDevice && configurationDevice.length == 1)
          {
            this.configurationDeviceUiid = configurationDevice[0].uuid;
          }
        });
    },
    async saveConfiguration (){
      this.calculateScaleFactor();
      let newRect = this.getCropBoxData();
      this.configuration.focusPoint = null;
      this.configuration.trigger = null;
      this.configuration.triggerLine = null;
      this.configuration.cropRect[0] = Math.round(newRect.left * this.scaleFactor);
      this.configuration.cropRect[1] = Math.round(newRect.top * this.scaleFactor);
      this.configuration.cropRect[2] = Math.round(newRect.width * this.scaleFactor);
      this.configuration.cropRect[3] = Math.round(newRect.height * this.scaleFactor);
      await this.axios.put(`/Actioncam/UpdateActioncamConfiguration?installationId=${ this.installationId }&deviceUuid=${ this.deviceId }`, this.configuration)
        .then((response) => {
          if (!response) {
            this.loading = false;
            return;
          }
          if (!response.status) {
            this.loading = false;
            return;
          }
          if (response.status != 200) {
            this.loading = false;
            return;
          }
          if (response.status == 200) {
            this.$snotify.success(this.$t('actionCamComponent.configurationSavedSuccessfully'));
          }
        });
    },
    async restartApp () {
      let commandRequest = {
        'Command': 'restartApp',
        'Args': ''
      };
      await this.axios.post(`/CentralDeviceManagement/ExecuteCommand?deviceUuid=${ this.configurationDeviceUiid }`, commandRequest)
        .then((response) => {
          if (response.data && response.data.output) {
            this.$snotify.success(this.$t('restartAppSuccessful'));
          }
        });
    },
    emitExif () {
      var bf = `data:image/png;base64,${ this.actioncamImage.base64Data }`
            var image = new Image();
            image.onload = () => {
                EXIF.getData(image, () => {
                  let exif = EXIF.pretty(image).split("\n");
                  exif = exif.filter(x => x.includes("Model :") || x.includes("PixelXDimension :") || x.includes("PixelYDimension :") || x.includes("ISOSpeedRatings :") || x.includes("WhiteBalance :") || x.includes("ExposureTime :") || x.includes("ExposureProgram :") || x.includes("FocalLength :") || x.includes("FNumber :"));
                  this.$emit("img-base", exif);
                });
            };
      image.src = bf;
    }
  }
}
</script>

<style>
body {
  box-sizing: content-box;
}
.cropperRed .cropper-face {
  background-color: red !important;
} 
#actioncamImageSelector {
  transform: rotate(90);
}
</style>


